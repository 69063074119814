<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>

    <b-card>
      <b-form v-on:submit.prevent.self="getData">
        <b-row>
          <b-col sm="3">
            <b-form-group>
              <label for="compare-option">Select An Option</label>
              <b-form-select
                required
                v-model="SelectedCompareOption"
                id="CompareOption"
                :plain="true"
                text-field="name"
                value-field="id"
                :options="CompareOptions"
              >
                <template slot="first">
                  <option :value="0" disabled>Select an Option</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <div v-if="SelectedCompareOption == 1">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <label for="from-date">First From Date :</label>
                    <b-form-input type="date" v-model="FromDate"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label for="to-date">First To Date :</label>
                    <b-form-input type="date" v-model="ToDate"></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <label for="from-date">Second From Date :</label>
                    <b-form-input type="date" v-model="FromDateTwo"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label for="to-date">Second To Date :</label>
                    <b-form-input type="date" v-model="ToDateTwo"></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div v-else-if="SelectedCompareOption == 2">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <label for="compare-option">Select Option 1</label>
                    <b-form-select
                      required
                      v-model="SelectedCompareFirst"
                      id="CompareOptionFirst"
                      :plain="true"
                      text-field="name"
                      value-field="id"
                      :options="CompareOptionsFirst"
                    >
                      <template slot="first">
                        <option :value="0" disabled>Select an Option</option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label for="compare-option">Select Option 2</label>
                    <b-form-select
                      required
                      v-model="SelectedCompareTwo"
                      id="CompareOptionSecond"
                      :plain="true"
                      text-field="name"
                      value-field="id"
                      :options="CompareOptionsSecond"
                    >
                      <template slot="first">
                        <option :value="0" disabled>Select an Option</option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <b-button class="mt-4" type="submit" size="sm" variant="primary">
                <i class="fa fa-dot-circle-o"></i> Get Reports
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card>
      <b-table striped hover :items="data"></b-table>
    </b-card>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import MasterService from "@/services/MasterService";
import OrganizationService from "@/services/OrganizationService";
import ReportService from "@/services/ReportService";
import CinemaService from "@/services/CinemaService";
import MovieService from "@/services/MovieService";

// jspdf-autotable
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";

export default {
  name: "TransactionReport",
  components: {
    Event,
    cSwitch
  },
  data: function() {
    return {
      SelectedCompareOption: null,
      CompareOptions: [
        { id: "1", name: "Periodic" },
        { id: "2", name: "Movies" }
      ],
      SelectedCompareFirst: null,
      CompareOptionsFirst: [
        { id: "1", name: "Periodic" },
        { id: "2", name: "Movies" }
      ],
      SelectedCompareSecond: null,
      CompareOptionsSecond: [
        { id: "1", name: "Periodic" },
        { id: "2", name: "Movies" }
      ],
      ToDate: "",
      FromDate: "",
      ToDateTwo: "",
      FromDateTwo: "",

      data: []
    };
  },
  beforeMount() {
    if (
      localStorage.getItem("UserRole") &&
      localStorage.getItem("UserRole") == "Cinema"
    ) {
      this.userPermission = false;
    } else {
    }
  },
  mounted() {},
  methods: {
    getData: function() {
      let payload = {};
      if (this.SelectedCompareOption == 1) {
        payload["Type"] = this.SelectedCompareOption;
        payload["StartDateOne"] = this.FromDate;
        payload["EndDateOne"] = this.ToDate;
        payload["StartDateTwo"] = this.FromDateTwo;
        payload["EndDateTwo"] = this.ToDateTwo;
      } else if (this.SelectedCompareOption == 2) {
        payload["Type"] = this.SelectedCompareOption;
        payload["OptionOne"] = this.SelectedCompareFirst;
        payload["OptionTwo"] = this.SelectedCompareSecond;
      }

      ReportService.getCompareReport(payload)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            console.log("Data", data.AllStats);
            this.data = data.AllStats;
          } else {
            console.log("Message", data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar("No data found");
        });
    },
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
</style>
